import React, { Suspense, lazy } from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import styled from "styled-components"
import { GlobalStyle } from "./globalStyles"
import Lottie from "react-lottie"
import animation from "./coxibakery.json"
import ReactGA from "react-ga"
import GARouteChangeTracker from "./components/GARouteChangeTracker"

import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE)
// ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE_STAGING)
ReactGA.pageview(window.location.pathname + window.location.search)

const ScrollToTop = lazy(() => import("./ScrollToTop"))
const Home = lazy(() => import("./pages/Home"))
const ProductDetails = lazy(() => import("./pages/ProductDetails"))
const SignIn = lazy(() => import("./pages/SignIn"))
const SignUp = lazy(() => import("./pages/SignUp"))
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"))
const CheckoutPage = lazy(() => import("./pages/Checkout"))
const ContactInfo = lazy(() => import("./pages/ContactInfo"))
const Review = lazy(() => import("./pages/Review"))
const Profile = lazy(() => import("./pages/Profile"))
const ProfileDetails = lazy(() => import("./pages/ProfileDetails"))
const Success = lazy(() => import("./pages/Success"))
const OrderSubmit = lazy(() => import("./pages/OrderSubmit"))
const FrequentAskQuestions = lazy(() => import("./pages/FooterPage/FrequentAskQuestions"))
const PaymentMethod = lazy(() => import("./pages/FooterPage/PaymentMethod"))
const DeliveryInfo = lazy(() => import("./pages/FooterPage/DeliveryInfo"))
const TermsOfService = lazy(() => import("./pages/FooterPage/TermsOfService"))
const PrivacyPolicy = lazy(() => import("./pages/FooterPage/PrivacyPolicy"))
const RefundPolicy = lazy(() => import("./pages/FooterPage/RefundPolicy"))
const CoxibakeryOrder = lazy(() => import("./pages/CoxibakeryOrder"))

const NotFound = lazy(() => import("./components/NotFound"))

const LottieWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`
const App = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }
  return (
    <Router>
      <Suspense
        fallback={
          <LottieWrapper>
            <Lottie options={defaultOptions} height={200} width={200} />
          </LottieWrapper>
        }
      >
        <GlobalStyle />
        <ToastContainer autoClose={2400} />
        <ScrollToTop />
        <GARouteChangeTracker />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/product-details/:id" component={ProductDetails} />
          <Route exact path="/signin" component={SignIn} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/checkout" component={CheckoutPage} />
          <Route exact path="/contactinfo" component={ContactInfo} />
          <Route exact path="/review" component={Review} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/profile-details" component={ProfileDetails} />
          <Route exact path="/success" component={Success} />
          <Route exact path="/order-submit" component={OrderSubmit} />
          <Route exact path="/frequent-questions" component={FrequentAskQuestions} />
          <Route exact path="/payment-method" component={PaymentMethod} />
          <Route exact path="/delivery-information" component={DeliveryInfo} />
          <Route exact path="/term-of-service" component={TermsOfService} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/refund-policy" component={RefundPolicy} />
          <Route exact path="/order-history-admin" component={CoxibakeryOrder} />
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </Router>
  )
}

export default App
