const CartActionTypes = {
    TOGGLE_CART_HIDDEN: 'TOGGLE_CART_HIDDEN',
    TOGGLE_CLOSE_CART: 'TOGGLE_CLOSE_CART',
    ADD_CAKE: 'ADD_CAKE',
    REMOVE_CAKE: 'REMOVE_CAKE',
    ADD_ITEM: 'ADD_ITEM',
    REMOVE_ITEM: 'REMOVE_ITEM',
    CLEAR_ITEM_FROM_CART: 'CLEAR_ITEM_FROM_CART',
    SUBMIT_USER_INFO: 'SUBMIT_USER_INFO',
    SUBMIT_PAYMENT_METHOD: 'SUBMIT_PAYMENT_METHOD',
    EMPTY_CART: 'EMPTY_CART'
}

export default CartActionTypes