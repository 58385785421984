import { LineUpCake, Instagram } from './Data'

const INITIAL_STATE = {
    // featured: FeaturedCake,
    lineUp: LineUpCake,
    instagram: Instagram
}

export const cakeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        default: return state;
    }
}