import { createStore, applyMiddleware } from "redux"
import logger from "redux-logger"
import rootReducer from "./root-reducer"
import { persistStore } from "redux-persist"

// const middlewares = [logger]

let middlewares = []
if (process.env.NODE_ENV === "development") {
  middlewares = [...middlewares, logger]
} else {
  middlewares = [...middlewares]
}

const store = createStore(rootReducer, applyMiddleware(...middlewares))

const persistor = persistStore(store)

export { store, persistor }
