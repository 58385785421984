export const LineUpCake = [
  {
    id: "Coxibakery-Berry-Garden-Cake",
    image:
      "https://res.cloudinary.com/coximomentz/image/upload/v1655265205/400x400/Coxibakery-Berry-Garden-Cake-1-min_lipshi.png",
    gallery: [
      "https://res.cloudinary.com/coximomentz/image/upload/v1655265117/800x800/Coxibakery-Berry-Garden-Cake-1-min_sryzi3.png",
      "https://res.cloudinary.com/coximomentz/image/upload/v1655265117/800x800/Coxibakery-Berry-Garden-Cake-2-min_wqu6uh.png",
    ],
    alt: "Coxibakery Berry Garden",
    name: "Berry Garden Cake",
    description:
      "This cheesecake has a perfect buttery digestive crust. With a smooth, creamy, and tangy yogurt cheesecake. Perfect layer of blueberry jelly and topped with fresh blueberries.",
    price: 129,
    ingredients: "Flour, Eggs, Sugar, Milk, Yogurt, Whipping Cream, Digestive Biscuits, Blueberry Jam, Blueberry, Kiwi",
    allergens: "Milk/Lactose, Eggs",
    weight: "Approx 600g to 800g",
    layers: "4 Layers",
    shape: "Round",
    measurement: "https://res.cloudinary.com/coximomentz/image/upload/v1619000554/Round_Measurement_nudddf.svg",
    serving: "2 - 10, depend on size",
    shelf: "1 - 2 days",
    lottie: "https://assets7.lottiefiles.com/private_files/lf30_dexm1ph5.json",
    premium: false,
    featured: false,
    newFlavour: true,
    season: false,
    custom: false,
    allSize: true,
    min6inch: false,
    addOn: [
      {
        id: 5,
        name: "Mini Topper",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Mini_Topper-min_epfvr7.jpg",
        price: 6,
      },
      {
        id: 6,
        name: "Topper",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Topper-min_lz9xyn.jpg",
        price: 10,
      },
    ],
  },
  {
    id: "Coxibakery-Lychee-Longan-Mousse-Cake",
    image:
      "https://res.cloudinary.com/coximomentz/image/upload/v1634303582/400x400/Coxibakery-Lyche%CC%81e-Longan-Mousse-Cake-1-min_jnli92.png",
    gallery: [
      "https://res.cloudinary.com/coximomentz/image/upload/v1634303055/800x800/Coxibakery-Lyche%CC%81e-Longan-Mousse-Cake-1-min_gwqvie.png",
      "https://res.cloudinary.com/coximomentz/image/upload/v1634303279/800x800/Coxibakery-Lyche%CC%81e-Longan-Mousse-Cake-2-min_j8jp6q.png",
      "https://res.cloudinary.com/coximomentz/image/upload/v1634303055/800x800/Coxibakery-Lyche%CC%81e-Longan-Mousse-Cake-3-min_ulkjnb.png",
    ],
    alt: "Coxibakery Lychée Longan Mousse",
    name: "Lychée & Longan Mousse Cake",
    description:
      "The floral flavour from the lychee is what makes this mousse cake so addictive and be surprised with the added strawberry flavor mousse.",
    price: 120,
    ingredients: "Flour, Eggs, Sugar, Milk, Yogurt, Whipping Cream, Strawberry Jam, Lychee, Longan, Jelly",
    allergens: "Milk/Lactose, Eggs",
    weight: "Approx 600g to 800g",
    layers: "4 Layers",
    shape: "Round",
    measurement: "https://res.cloudinary.com/coximomentz/image/upload/v1619000554/Round_Measurement_nudddf.svg",
    serving: "2 - 10, depend on size",
    shelf: "1 - 2 days",
    lottie: "https://assets1.lottiefiles.com/private_files/lf30_szalhphj.json",
    premium: false,
    featured: false,
    newFlavour: true,
    season: false,
    custom: false,
    allSize: true,
    min6inch: false,
  },
  {
    id: "Coxibakery-Berry-Melon-Cake",
    image:
      "https://res.cloudinary.com/coximomentz/image/upload/v1631362104/400x400/Coxibakery-Berry-Melon-Cake-1-min_hrsbn1.png",
    gallery: [
      "https://res.cloudinary.com/coximomentz/image/upload/v1631362174/800x800/Coxibakery-Berry-Melon-Cake-1-min_ino4hu.png",
      "https://res.cloudinary.com/coximomentz/image/upload/v1631362172/800x800/Coxibakery-Berry-Melon-Cake-2-min_lnhvmy.png",
      "https://res.cloudinary.com/coximomentz/image/upload/v1631362174/800x800/Coxibakery-Berry-Melon-Cake-3-min_h9y92e.png",
    ],
    alt: "Coxibakery Berry Melon",
    name: "Berry Melon",
    description:
      "When Summer’s love melon cake meets strawberry!🍈🍓 There's nothing more refreshing than perfectly ripe, in-season melon. And that fresh flavor gets elevated further when paired with juicy strawberries and sweet melon.",
    price: 120,
    ingredients: "Flour, Eggs, Sugar, Whipping Cream, Butter, Salt, Fresh Strawberry, Fresh Melon",
    allergens: "Milk/Lactose, Eggs",
    weight: "Approx 600g to 800g",
    layers: "5 Layers",
    shape: "Round",
    measurement: "https://res.cloudinary.com/coximomentz/image/upload/v1619000554/Round_Measurement_nudddf.svg",
    serving: "2 - 10, depend on size",
    shelf: "1 - 2 days",
    lottie: "https://assets9.lottiefiles.com/private_files/lf30_sse0mput.json",
    premium: false,
    featured: true,
    newFlavour: false,
    season: false,
    custom: false,
    allSize: true,
    min6inch: false,
    addOn: [
      {
        id: 5,
        name: "Mini Topper",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Mini_Topper-min_epfvr7.jpg",
        price: 6,
      },
      {
        id: 6,
        name: "Topper",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Topper-min_lz9xyn.jpg",
        price: 10,
      },
    ],
  },
  {
    id: "Coxibakery-Grapeness-Cake",
    image:
      "https://res.cloudinary.com/coximomentz/image/upload/v1626258493/400x400/Coxibakery-Grapeness-Cake-1-min_w48vdn.png",
    gallery: [
      "https://res.cloudinary.com/coximomentz/image/upload/v1626257911/800x800/Coxibakery-Grapeness-Cake-1-min_sqkg5d.png",
      "https://res.cloudinary.com/coximomentz/image/upload/v1626257911/800x800/Coxibakery-Grapeness-Cake-2-min_mcs2g2.png",
      "https://res.cloudinary.com/coximomentz/image/upload/v1626257911/800x800/Coxibakery-Grapeness-Cake-3-min_vaevdh.png",
    ],
    alt: "Coxibakery Grapeness",
    name: "Grapeness",
    description:
      "Any grapes lover out there? 💚 We know how much you love green grapes, this is for you! Introducing our new added cake, “Grapeness”, feel the softness of chiffon cake with the refreshing taste of green grapes. Delight in every bite.💚",
    price: 138,
    ingredients: "Flour, Eggs, Sugar, Whipping Cream, Butter, Salt, Fresh Grape",
    allergens: "Milk/Lactose, Eggs",
    weight: "Approx 600g to 800g",
    layers: "5 Layers",
    shape: "Square",
    measurement: "https://res.cloudinary.com/coximomentz/image/upload/v1619000554/Square_Measurement_lre8p2.svg",
    serving: "2 - 6, depend on size",
    shelf: "1 - 2 days",
    lottie: "https://res.cloudinary.com/coximomentz/raw/upload/v1626266094/Grapeness_Cake_st5ctq.json",
    premium: false,
    featured: false,
    newFlavour: false,
    season: false,
    custom: false,
    allSize: false,
    min6inch: false,
    addOn: [
      {
        id: 1,
        name: "Debossing Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943606/Debossing-min_goo6tc.jpg",
        price: 6,
      },
      {
        id: 2,
        name: "Embossing Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Embossing-min_kukhe5.jpg",
        price: 8,
      },
      {
        id: 3,
        name: "Luggage Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Luggage-min_a6zze5.jpg",
        price: 8,
      },
      {
        id: 4,
        name: "Ribbon Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633944167/Ribbon-min_wemx08.jpg",
        price: 8,
      },
      {
        id: 5,
        name: "Mini Topper",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Mini_Topper-min_epfvr7.jpg",
        price: 6,
      },
      {
        id: 6,
        name: "Topper",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Topper-min_lz9xyn.jpg",
        price: 10,
      },
    ],
  },
  // {
  //   id: "Coxibakery-Japanese-Strawberry-Cake",
  //   image:
  //     "https://res.cloudinary.com/coximomentz/image/upload/v1617333879/400x400/Coxibakery-Japanese-Strawberry-Cake-1-min_nbck32.png",
  //   gallery: [
  //     "https://res.cloudinary.com/coximomentz/image/upload/v1617333778/800x800/Coxibakery-Japanese-Strawberry-Cake-1-min_k6arb5.png",
  //     "https://res.cloudinary.com/coximomentz/image/upload/v1617333778/800x800/Coxibakery-Japanese-Strawberry-Cake-2-min_xoy4f8.png",
  //   ],
  //   alt: "Coxibakery Japanese Strawberry Cake",
  //   name: "Premium Japanese Strawberry Shortcake",
  //   description:
  //     "All about strawberries!🍓 Moist, airy, and light Japanese strawberry shortcake with homemade chantilly cream frosting and Premium Japanese Strawberry.",
  //   price: 288,
  //   ingredients: "Flour, Eggs, Sugar, Whipping Cream, Butter, Salt, Fresh Japanese Strawberry",
  //   allergens: "Milk/Lactose, Eggs",
  //   weight: "Approx 600g to 800g",
  //   layers: "5 Layers",
  //   shape: "Round",
  //   measurement: "https://res.cloudinary.com/coximomentz/image/upload/v1619000554/Round_Measurement_nudddf.svg",
  //   serving: "2 - 6, depend on size",
  //   shelf: "1 - 2 days",
  //   lottie: "https://assets7.lottiefiles.com/private_files/lf30_0p92bvel.json",
  //   premium: true,
  //   featured: false,
  //   new: false,
  //   season: false,
  //   custom: false,
  //   allSize: false,
  //   min6inch: false,
  //   addOn: [
  //     {
  //       id: 1,
  //       name: "Debossing Tag",
  //       image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943606/Debossing-min_goo6tc.jpg",
  //       price: 6,
  //     },
  //     {
  //       id: 2,
  //       name: "Embossing Tag",
  //       image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Embossing-min_kukhe5.jpg",
  //       price: 8,
  //     },
  //     {
  //       id: 3,
  //       name: "Luggage Tag",
  //       image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Luggage-min_a6zze5.jpg",
  //       price: 8,
  //     },
  //     {
  //       id: 4,
  //       name: "Ribbon Tag",
  //       image: "https://res.cloudinary.com/coximomentz/image/upload/v1633944167/Ribbon-min_wemx08.jpg",
  //       price: 8,
  //     },
  //     {
  //       id: 5,
  //       name: "Mini Topper",
  //       image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Mini_Topper-min_epfvr7.jpg",
  //       price: 6,
  //     },
  //     {
  //       id: 6,
  //       name: "Topper",
  //       image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Topper-min_lz9xyn.jpg",
  //       price: 10,
  //     },
  //   ],
  // },
  // {
  //   id: "Coxibakery-Watermelon-Mousse-Cake",
  //   image:
  //     "https://res.cloudinary.com/coximomentz/image/upload/v1616478502/400x400/Coxibakery-Watermelon-Mousse-Cake-1_kvqee1.png",
  //   gallery: [
  //     "https://res.cloudinary.com/coximomentz/image/upload/v1616478449/800x800/Coxibakery-Watermelon-Mousse-Cake-1-min_qykmmf.png",
  //     "https://res.cloudinary.com/coximomentz/image/upload/v1616478449/800x800/Coxibakery-Watermelon-Mousse-Cake-2-min_hxvqyp.png",
  //     "https://res.cloudinary.com/coximomentz/image/upload/v1616479548/800x800/Coxibakery-Watermelon-Mousse-Cake-3-min_nvpjsc.png",
  //   ],
  //   alt: "Coxibakery Watermelon Mousse Cake",
  //   name: "Watermelon Mousse Cake",
  //   description:
  //     "Watermelon + cake = sounds too good to be true!🍉🍉💦 The watermelon mousse cake is absolutely delicious and refreshing. It isn’t overpowering and it isn’t too sweet either. The mousse itself is soft, decadent and creamy, together with the slice of watermelon.",
  //   price: 88,
  //   ingredients: "Flour, Eggs, Sugar, Whipping Cream, Butter, Fresh Watermelon, Salt",
  //   allergens: "Milk/Lactose, Eggs",
  //   weight: "Approx 0.3kg - 1.2kg, depend on size",
  //   layers: "4 Layers",
  //   shape: "Round",
  //   measurement: "https://res.cloudinary.com/coximomentz/image/upload/v1619000554/Round_Measurement_nudddf.svg",
  //   serving: "2 - 10, depend on size",
  //   shelf: "1 - 2 days",
  //   lottie: "https://assets9.lottiefiles.com/private_files/lf30_0dkc8cy8.json",
  //   premium: false,
  //   featured: false,
  //   new: false,
  //   season: false,
  //   custom: false,
  //   allSize: true,
  //   min6inch: false,
  //   addOn: [
  //     {
  //       id: 1,
  //       name: "Debossing Tag",
  //       image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943606/Debossing-min_goo6tc.jpg",
  //       price: 6,
  //     },
  //     {
  //       id: 2,
  //       name: "Embossing Tag",
  //       image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Embossing-min_kukhe5.jpg",
  //       price: 8,
  //     },
  //     {
  //       id: 3,
  //       name: "Luggage Tag",
  //       image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Luggage-min_a6zze5.jpg",
  //       price: 8,
  //     },
  //     {
  //       id: 4,
  //       name: "Ribbon Tag",
  //       image: "https://res.cloudinary.com/coximomentz/image/upload/v1633944167/Ribbon-min_wemx08.jpg",
  //       price: 8,
  //     },
  //     {
  //       id: 5,
  //       name: "Mini Topper",
  //       image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Mini_Topper-min_epfvr7.jpg",
  //       price: 6,
  //     },
  //     {
  //       id: 6,
  //       name: "Topper",
  //       image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Topper-min_lz9xyn.jpg",
  //       price: 10,
  //     },
  //   ],
  // },
  {
    id: "Coxibakery-Mango-Mousse-Cake",
    image:
      "https://res.cloudinary.com/coximomentz/image/upload/v1634530234/400x400/Coxibakery-Mango-Mousse-Cake-1-min_zh8zsm.png",
    gallery: [
      "https://res.cloudinary.com/coximomentz/image/upload/v1634530178/800x800/Coxibakery-Mango-Mousse-Cake-1-min_zeub7e.png",
      "https://res.cloudinary.com/coximomentz/image/upload/v1634530178/800x800/Coxibakery-Mango-Mousse-Cake-2-min_u2x6kg.png",
      "https://res.cloudinary.com/coximomentz/image/upload/v1615898872/800x800/Coxibakery-Mango-Mousse-Cake-3-min_ph4esg.png",
    ],
    alt: "Coxibakery Mango Mousse Cake",
    name: "Mango Mousse Cake",
    description:
      "Meet the “Eggy”.🥚🍳 This mango mousse cake recipe is made with a fluffy chiffon cake, real mango mousse, fresh mango and mango jelly layer. If you’re looking for a fruity, light and airy cake for your next special occasion, this is the cake for you.",
    price: 105,
    ingredients: "Flour, Eggs, Sugar, Whipping Cream, Butter, Fresh Mango, Salt, Vanilla, Mango Jelly, White Chocolate",
    allergens: "Milk/Lactose, Eggs",
    weight: "Approx 0.3kg - 1.2kg, depend on size",
    layers: "5 Layers",
    shape: "Round",
    measurement: "https://res.cloudinary.com/coximomentz/image/upload/v1619000554/Round_Measurement_nudddf.svg",
    serving: "2 - 10, depend on size",
    shelf: "2 - 3 days",
    lottie: "https://assets3.lottiefiles.com/private_files/lf30_vchrfm9e.json",
    premium: false,
    featured: false,
    new: false,
    season: false,
    custom: false,
    allSize: true,
    min6inch: false,
    addOn: [
      {
        id: 1,
        name: "Debossing Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943606/Debossing-min_goo6tc.jpg",
        price: 6,
      },
      {
        id: 2,
        name: "Embossing Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Embossing-min_kukhe5.jpg",
        price: 8,
      },
      {
        id: 3,
        name: "Luggage Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Luggage-min_a6zze5.jpg",
        price: 8,
      },
      {
        id: 4,
        name: "Ribbon Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633944167/Ribbon-min_wemx08.jpg",
        price: 8,
      },
      {
        id: 5,
        name: "Mini Topper",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Mini_Topper-min_epfvr7.jpg",
        price: 6,
      },
      {
        id: 6,
        name: "Topper",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Topper-min_lz9xyn.jpg",
        price: 10,
      },
    ],
  },
  {
    id: "Coxibakery-Chocolate-Chestnut-Cake",
    image:
      "https://res.cloudinary.com/coximomentz/image/upload/v1616122564/400x400/Coxibakery-Chocolate-Chestnut-Cake-1-min_nzgj2d.png",
    gallery: [
      "https://res.cloudinary.com/coximomentz/image/upload/v1616122635/800x800/Coxibakery-Chocolate-Chestnut-Cake-1-min_mqhda6.png",
      "https://res.cloudinary.com/coximomentz/image/upload/v1616122635/800x800/Coxibakery-Chocolate-Chestnut-Cake-2-min_mwvpdm.png",
      "https://res.cloudinary.com/coximomentz/image/upload/v1616479116/800x800/Coxibakery-Chocolate-Chestnut-Cake-3-min_niifo0.png",
    ],
    alt: "Coxibakery Chocolate Chestnut Cake",
    name: "Chocolate Chestnut Cake",
    description:
      "A treat for chestnut 🌰 lover! Light and fluffy dark chocolate chiffon cake layer with chestnut cream and finely chopped candied chestnut!",
    price: 94,
    ingredients: "Flour, Eggs, Sugar, Whipping Cream, Butter, Cocoa Powder, Roasted Chestnut, Salt",
    allergens: "Milk/Lactose, Eggs, Chestnuts",
    weight: "Approx 0.3kg - 1.2kg, depend on size",
    layers: "5 Layers",
    shape: "Round",
    measurement: "https://res.cloudinary.com/coximomentz/image/upload/v1619000554/Round_Measurement_nudddf.svg",
    serving: "2 - 10, depend on size",
    shelf: "2 - 3 days",
    lottie: "https://assets5.lottiefiles.com/private_files/lf30_rtcp8yph.json",
    premium: false,
    featured: false,
    new: false,
    season: false,
    custom: false,
    allSize: true,
    min6inch: false,
    addOn: [
      {
        id: 1,
        name: "Debossing Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943606/Debossing-min_goo6tc.jpg",
        price: 6,
      },
      {
        id: 2,
        name: "Embossing Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Embossing-min_kukhe5.jpg",
        price: 8,
      },
      {
        id: 3,
        name: "Luggage Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Luggage-min_a6zze5.jpg",
        price: 8,
      },
      {
        id: 4,
        name: "Ribbon Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633944167/Ribbon-min_wemx08.jpg",
        price: 8,
      },
      {
        id: 5,
        name: "Mini Topper",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Mini_Topper-min_epfvr7.jpg",
        price: 6,
      },
      {
        id: 6,
        name: "Topper",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Topper-min_lz9xyn.jpg",
        price: 10,
      },
    ],
  },
  {
    id: "Coxibakery-Oreo-Cream-Cheese-Cake",
    image:
      "https://res.cloudinary.com/coximomentz/image/upload/v1631357648/400x400/Coxibakery-Oreo-Cream-Cheese-Cake-1-min_lizfqe.png",
    gallery: [
      "https://res.cloudinary.com/coximomentz/image/upload/v1631357677/800x800/Coxibakery-Oreo-Cream-Cheese-Cake-1-min_xnaxpu.png",
      "https://res.cloudinary.com/coximomentz/image/upload/v1615898873/800x800/Coxibakery-Oreo-Cream-Cheese-Cake-2-min_nyvrbw.png",
      "https://res.cloudinary.com/coximomentz/image/upload/v1615898874/800x800/Coxibakery-Oreo-Cream-Cheese-Cake-3-min_ddbf8j.png",
    ],
    alt: "Coxibakery Oreo Cream Cheese Cake",
    name: "Susuwatari Oreo Cream Cheese Cake",
    description:
      "The ultimate cake inspired from the movie- Spirited Away. Fabulous combination of soft chocolate chiffon cake with layers of oreo ream cheese frosting and Oreo cookies for the cookies and cream cheese fans.",
    price: 78,
    ingredients: "Flour, Eggs, Sugar, Whipping Cream, Butter, Salt, Cocoa Powder, Cream Cheese, Oreo",
    allergens: "Milk/Lactose, Eggs",
    weight: "Approx 0.3kg - 1.2kg, depend on size",
    layers: "5 Layers",
    shape: "Round",
    measurement: "https://res.cloudinary.com/coximomentz/image/upload/v1619000554/Round_Measurement_nudddf.svg",
    serving: "2 - 10, depend on size",
    shelf: "3 - 5 days",
    lottie: "https://assets2.lottiefiles.com/private_files/lf30_19csjazv.json",
    premium: false,
    featured: false,
    new: false,
    season: false,
    custom: false,
    allSize: true,
    min6inch: false,
    addOn: [
      {
        id: 1,
        name: "Debossing Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943606/Debossing-min_goo6tc.jpg",
        price: 6,
      },
      {
        id: 2,
        name: "Embossing Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Embossing-min_kukhe5.jpg",
        price: 8,
      },
      {
        id: 3,
        name: "Luggage Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Luggage-min_a6zze5.jpg",
        price: 8,
      },
      {
        id: 4,
        name: "Ribbon Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633944167/Ribbon-min_wemx08.jpg",
        price: 8,
      },
      {
        id: 5,
        name: "Mini Topper",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Mini_Topper-min_epfvr7.jpg",
        price: 6,
      },
      {
        id: 6,
        name: "Topper",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Topper-min_lz9xyn.jpg",
        price: 10,
      },
    ],
  },
  {
    id: "Coxibakery-Melon-Cake",
    image:
      "https://res.cloudinary.com/coximomentz/image/upload/v1621667731/400x400/Coxibakery-Melon-Cake-1-min_dgbvwi.png",
    gallery: [
      "https://res.cloudinary.com/coximomentz/image/upload/v1621667871/800x800/Coxibakery-Melon-Cake-1-min_vggoft.png",
      "https://res.cloudinary.com/coximomentz/image/upload/v1621667872/800x800/Coxibakery-Melon-Cake-2-min_psa0mz.png",
    ],
    alt: "Coxibakery Melon Cake",
    name: "Summer's Love - Melon Cake",
    description: "One bite of this cake and you’ll be saying, “Thanks a melon!”🍈 Another light and refreshing cake~",
    price: 99,
    ingredients: "Flour, Eggs, Sugar, Whipping Cream, Butter, Salt, Fresh Melon",
    allergens: "Milk/Lactose, Eggs",
    weight: "Approx 0.3kg - 1.2kg, depend on size",
    layers: "5 Layers",
    shape: "Square",
    measurement: "https://res.cloudinary.com/coximomentz/image/upload/v1619000554/Square_Measurement_lre8p2.svg",
    serving: "2 - 10, depend on size",
    shelf: "1 - 2 days",
    lottie: "https://assets5.lottiefiles.com/private_files/lf30_q2dj8daa.json",
    premium: false,
    featured: false,
    new: false,
    season: false,
    custom: false,
    min6inch: false,
    addOn: [
      {
        id: 1,
        name: "Debossing Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943606/Debossing-min_goo6tc.jpg",
        price: 6,
      },
      {
        id: 2,
        name: "Embossing Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Embossing-min_kukhe5.jpg",
        price: 8,
      },
      {
        id: 3,
        name: "Luggage Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Luggage-min_a6zze5.jpg",
        price: 8,
      },
      {
        id: 4,
        name: "Ribbon Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633944167/Ribbon-min_wemx08.jpg",
        price: 8,
      },
      {
        id: 5,
        name: "Mini Topper",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Mini_Topper-min_epfvr7.jpg",
        price: 6,
      },
      {
        id: 6,
        name: "Topper",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Topper-min_lz9xyn.jpg",
        price: 10,
      },
    ],
  },
  {
    id: "Coxibakery-Belgian-Chocolate-Strawberry-Cake",
    image:
      "https://res.cloudinary.com/coximomentz/image/upload/v1616055004/400x400/Coxibakery-Belgian-Chocolate-Strawberry-Cake-1-min_ib7y3k.png",
    gallery: [
      "https://res.cloudinary.com/coximomentz/image/upload/v1615898870/800x800/Coxibakery-Belgian-Chocolate-Strawberry-Cake-1-min_i0oyhd.png",
      "https://res.cloudinary.com/coximomentz/image/upload/v1615898870/800x800/Coxibakery-Belgian-Chocolate-Strawberry-Cake-2-min_xgcxhd.png",
    ],
    alt: "Coxibakery Belgian Chocolate Strawberry Cake",
    name: "Premium Belgian Chocolate Cake",
    description:
      "Checkerboard Strawberry Chocolate Cake~Moist chocolate and strawberry cake layered with fresh Korean strawberries and Belgian chocolate frosting. This is one of those cakes that just brings out the “ooohs” and “ahhhhs” – both for the look and the taste.",
    price: 126,
    ingredients:
      "Flour, Eggs, Sugar, Whipping Cream, Butter, Salt, Cocoa Powder, Fresh Korean Strawberry, Premium Belgian Chocolate",
    allergens: "Milk/Lactose, Eggs",
    weight: "Approx 0.3kg - 1.2kg, depend on size",
    layers: "3 Layers",
    shape: "Round",
    measurement: "https://res.cloudinary.com/coximomentz/image/upload/v1619000554/Round_Measurement_nudddf.svg",
    serving: "2 - 10, depend on size",
    shelf: "2 - 3 days",
    lottie: "https://assets4.lottiefiles.com/private_files/lf30_ujq2ugeh.json",
    premium: false,
    featured: false,
    new: false,
    season: false,
    custom: false,
    allSize: true,
    min6inch: false,
    addOn: [
      {
        id: 1,
        name: "Debossing Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943606/Debossing-min_goo6tc.jpg",
        price: 6,
      },
      {
        id: 2,
        name: "Embossing Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Embossing-min_kukhe5.jpg",
        price: 8,
      },
      {
        id: 3,
        name: "Luggage Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Luggage-min_a6zze5.jpg",
        price: 8,
      },
      {
        id: 4,
        name: "Ribbon Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633944167/Ribbon-min_wemx08.jpg",
        price: 8,
      },
      {
        id: 5,
        name: "Mini Topper",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Mini_Topper-min_epfvr7.jpg",
        price: 6,
      },
      {
        id: 6,
        name: "Topper",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Topper-min_lz9xyn.jpg",
        price: 10,
      },
    ],
  },
  {
    id: "Coxibakery-Signature-Korean-Strawberry-Cake",
    image:
      "https://res.cloudinary.com/coximomentz/image/upload/v1619256341/400x400/Coxibakery-Signature-Korean-Strawberry-Cake-1-min_sfsdpb.png",
    gallery: [
      "https://res.cloudinary.com/coximomentz/image/upload/v1619256444/800x800/Coxibakery-Signature-Korean-Strawberry-Cake-1-min_pttu5z.png",
      "https://res.cloudinary.com/coximomentz/image/upload/v1619256445/800x800/Coxibakery-Signature-Korean-Strawberry-Cake-2-min_n1smpg.png",
    ],
    alt: "Coxibakery Signature Korean Strawberry Cake",
    name: "Cupid's True Love",
    description:
      "Here’s the ultimate Strawberry Lovers Cake. This fresh strawberry cake is made from Korean’s strawberries, full of sweet, strawberry flavor! You’ll fall in love with it!",
    price: 101,
    ingredients: "Flour, Eggs, Sugar, Whipping Cream, Butter, Salt, Fresh Korean Strawberry, Strawberry Jelly",
    allergens: "Milk/Lactose, Eggs",
    weight: "Approx 0.3kg - 1.2kg, depend on size",
    layers: "5 Layers",
    shape: "Round",
    measurement: "https://res.cloudinary.com/coximomentz/image/upload/v1619000554/Round_Measurement_nudddf.svg",
    serving: "2 - 10, depend on size",
    shelf: "2 - 3 days",
    lottie: "https://assets10.lottiefiles.com/private_files/lf30_xnzpd0pf.json",
    premium: false,
    featured: true,
    new: false,
    season: false,
    custom: false,
    allSize: true,
    min6inch: false,
    addOn: [
      {
        id: 1,
        name: "Debossing Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943606/Debossing-min_goo6tc.jpg",
        price: 6,
      },
      {
        id: 2,
        name: "Embossing Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Embossing-min_kukhe5.jpg",
        price: 8,
      },
      {
        id: 3,
        name: "Luggage Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Luggage-min_a6zze5.jpg",
        price: 8,
      },
      {
        id: 4,
        name: "Ribbon Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633944167/Ribbon-min_wemx08.jpg",
        price: 8,
      },
      {
        id: 5,
        name: "Mini Topper",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Mini_Topper-min_epfvr7.jpg",
        price: 6,
      },
      {
        id: 6,
        name: "Topper",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Topper-min_lz9xyn.jpg",
        price: 10,
      },
    ],
  },
  {
    id: "Coxibakery-Mix-Fruit-Cake",
    image:
      "https://res.cloudinary.com/coximomentz/image/upload/v1616055005/400x400/Coxibakery-Mix-Fruit-Cake-1-min_qahovy.png",
    gallery: [
      "https://res.cloudinary.com/coximomentz/image/upload/v1615898873/800x800/Coxibakery-Mix-Fruit-Cake-1-min_iyn9bd.png",
      "https://res.cloudinary.com/coximomentz/image/upload/v1615898873/800x800/Coxibakery-Mix-Fruit-Cake-2-min_hyxjyz.png",
      "https://res.cloudinary.com/coximomentz/image/upload/v1615898873/800x800/Coxibakery-Mix-Fruit-Cake-3-min_afxhl0.png",
    ],
    alt: "Coxibakery Mix Fruit Cake - Topped with Grape, Strawberry, Kiwi, Orange and Mango",
    name: "The Ordinary Mixed Fruit Cake",
    description:
      "Fresh cream mixed fruit cake! A fruit love’s heaven!🍓🍇🍊🥝🥭 A simple yet refreshing fruit cake with less sweet cream frosting, topped with fresh fruits is all you need in the Sunny days!",
    price: 75,
    ingredients:
      "Flour, Eggs, Sugar, Whipping Cream, Butter, Salt, Fresh Strawberry, Fresh Grape, Fresh Orange, Fresh Kiwi, Fresh Mango",
    allergens: "Milk/Lactose, Eggs",
    weight: "Approx 0.3kg - 1.2kg, depend on size",
    layers: "5 Layers",
    shape: "Round",
    measurement: "https://res.cloudinary.com/coximomentz/image/upload/v1619000554/Round_Measurement_nudddf.svg",
    serving: "2 - 10, depend on size",
    shelf: "2 - 3 days",
    lottie: "https://assets3.lottiefiles.com/private_files/lf30_vpijpyv8.json",
    premium: false,
    featured: false,
    new: false,
    season: false,
    custom: false,
    allSize: false,
    min6inch: true,
    addOn: [
      {
        id: 1,
        name: "Debossing Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943606/Debossing-min_goo6tc.jpg",
        price: 6,
      },
      {
        id: 2,
        name: "Embossing Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Embossing-min_kukhe5.jpg",
        price: 8,
      },
      {
        id: 3,
        name: "Luggage Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Luggage-min_a6zze5.jpg",
        price: 8,
      },
      {
        id: 4,
        name: "Ribbon Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633944167/Ribbon-min_wemx08.jpg",
        price: 8,
      },
      {
        id: 5,
        name: "Mini Topper",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Mini_Topper-min_epfvr7.jpg",
        price: 6,
      },
      {
        id: 6,
        name: "Topper",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Topper-min_lz9xyn.jpg",
        price: 10,
      },
    ],
  },
  {
    id: "Coxibakery-Charlotte-Cake-with-Green-Grape",
    image:
      "https://res.cloudinary.com/coximomentz/image/upload/v1616055004/400x400/Coxibakery-Charlotte-Cake-with-Green-Grape-1-min_jpwrjd.png",
    gallery: [
      "https://res.cloudinary.com/coximomentz/image/upload/v1615898870/800x800/Coxibakery-Charlotte-Cake-with-Green-Grape-1-min_ky03op.png",
      "https://res.cloudinary.com/coximomentz/image/upload/v1615898870/800x800/Coxibakery-Charlotte-Cake-with-Green-Grape-2-min_h1igkz.png",
    ],
    alt: "Coxibakery Charlotte Cake with Green Grape",
    name: "Green Grape Charlotte Cake",
    description: "Something green💚 Soft, moist and absolutely delicious yogurt cake with sweet green grape!",
    price: 110,
    ingredients: "Flour, Eggs, Sugar, Whipping Cream, Butter, Salt, Yogurt, Fresh Grape",
    allergens: "Milk/Lactose, Eggs",
    weight: "Approx 0.3kg - 1.2kg, depend on size",
    layers: "3 Layers",
    shape: "Round",
    measurement: "https://res.cloudinary.com/coximomentz/image/upload/v1619000554/Round_Measurement_nudddf.svg",
    serving: "2 - 10, depend on size",
    shelf: "2 - 3 days",
    lottie: "https://assets1.lottiefiles.com/private_files/lf30_pru4y91a.json",
    premium: false,
    featured: false,
    new: false,
    season: false,
    custom: false,
    allSize: true,
    min6inch: false,
    addOn: [
      {
        id: 1,
        name: "Debossing Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943606/Debossing-min_goo6tc.jpg",
        price: 6,
      },
      {
        id: 2,
        name: "Embossing Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Embossing-min_kukhe5.jpg",
        price: 8,
      },
      {
        id: 3,
        name: "Luggage Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Luggage-min_a6zze5.jpg",
        price: 8,
      },
      {
        id: 4,
        name: "Ribbon Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633944167/Ribbon-min_wemx08.jpg",
        price: 8,
      },
      {
        id: 5,
        name: "Mini Topper",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Mini_Topper-min_epfvr7.jpg",
        price: 6,
      },
      {
        id: 6,
        name: "Topper",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Topper-min_lz9xyn.jpg",
        price: 10,
      },
    ],
  },
  {
    id: "Coxibakery-Signature-Bittersweet-Chocolate-Banana",
    image:
      "https://res.cloudinary.com/coximomentz/image/upload/v1616055005/400x400/Coxibakery-Signature-Bittersweet-Chocolate-Banana-1-min_l5bcjk.png",
    gallery: [
      "https://res.cloudinary.com/coximomentz/image/upload/v1615898873/800x800/Coxibakery-Signature-Bittersweet-Chocolate-Banana-1-min_dwxem4.png",
      "https://res.cloudinary.com/coximomentz/image/upload/v1615898874/800x800/Coxibakery-Signature-Bittersweet-Chocolate-Banana-2-min_x3lewh.png",
      "https://res.cloudinary.com/coximomentz/image/upload/v1615898874/800x800/Coxibakery-Signature-Bittersweet-Chocolate-Banana-3-min_tq1f2d.png",
    ],
    alt: "Coxibakery Signature Bittersweet Chocolate Banana",
    name: "Matchi Belgian Chocolate Banana",
    description:
      "Rich, Luscious, Fluffy and Chocolately! Introducing our signature “Matchi”, the perfect match of Banana and bittersweet chocolate 🍫",
    price: 108,
    ingredients:
      "Flour, Eggs, Sugar, Whipping Cream, Butter, Salt, Cocoa Powder, Premium Belgian Chocolate, Fresh Banana",
    allergens: "Milk/Lactose, Eggs",
    weight: "Approx 0.3kg - 1.2kg, depend on size",
    layers: "3 Layers",
    shape: "Round",
    measurement: "https://res.cloudinary.com/coximomentz/image/upload/v1619000554/Round_Measurement_nudddf.svg",
    serving: "2 - 10, depend on size",
    shelf: "2 - 3 days",
    lottie: "https://assets5.lottiefiles.com/private_files/lf30_pqqdak4f.json",
    premium: false,
    featured: true,
    new: false,
    season: false,
    custom: false,
    allSize: true,
    min6inch: false,
    addOn: [
      {
        id: 1,
        name: "Debossing Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943606/Debossing-min_goo6tc.jpg",
        price: 6,
      },
      {
        id: 2,
        name: "Embossing Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Embossing-min_kukhe5.jpg",
        price: 8,
      },
      {
        id: 3,
        name: "Luggage Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Luggage-min_a6zze5.jpg",
        price: 8,
      },
      {
        id: 4,
        name: "Ribbon Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633944167/Ribbon-min_wemx08.jpg",
        price: 8,
      },
      {
        id: 5,
        name: "Mini Topper",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Mini_Topper-min_epfvr7.jpg",
        price: 6,
      },
      {
        id: 6,
        name: "Topper",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Topper-min_lz9xyn.jpg",
        price: 10,
      },
    ],
  },
  // {
  //   id: "Coxibakery-Matcha-Green-Tea-with-Red-Bean-Layer-Cake",
  //   image:
  //     "https://res.cloudinary.com/coximomentz/image/upload/v1616055005/400x400/Coxibakery-Matcha-Green-Tea-with-Red-Bean-Layer-Cake-1-min_tepv1d.png",
  //   gallery: [
  //     "https://res.cloudinary.com/coximomentz/image/upload/v1615898872/800x800/Coxibakery-Matcha-Green-Tea-with-Red-Bean-Layer-Cake-1-min_qazal6.png",
  //     "https://res.cloudinary.com/coximomentz/image/upload/v1615898872/800x800/Coxibakery-Matcha-Green-Tea-with-Red-Bean-Layer-Cake-2-min_lsk02x.png",
  //     "https://res.cloudinary.com/coximomentz/image/upload/v1615898873/800x800/Coxibakery-Matcha-Green-Tea-with-Red-Bean-Layer-Cake-3-min_j2py3p.png",
  //   ],
  //   alt: "Coxibakery Matcha Green Tea with Red Bean Layer Cake",
  //   name: "Matcha Red Bean Cake",
  //   description: "Japanese Matcha Red Bean Layer Cake",
  //   price: 78,
  //   ingredients: "Flour, Eggs, Sugar, Whipping Cream, Cream Cheese, Butter, Salt, Match Powder, Red Bean, Raisin",
  //   allergens: "Milk/Lactose, Eggs",
  //   weight: "Approx 0.3kg - 1.2kg, depend on size",
  //   layers: "5 Layers",
  //   shape: "Square",
  //   measurement: "https://res.cloudinary.com/coximomentz/image/upload/v1619000554/Square_Measurement_lre8p2.svg",
  //   serving: "2 - 10, depend on size",
  //   shelf: "3 - 5 days",
  //   lottie: "https://assets6.lottiefiles.com/private_files/lf30_f1z12bys.json",
  //   premium: false,
  //   featured: false,
  //   new: false,
  //   season: false,
  //   custom: false,
  //   min6inch: false,
  //   addOn: [
  //     {
  //       id: 1,
  //       name: "Debossing Tag",
  //       image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943606/Debossing-min_goo6tc.jpg",
  //       price: 6,
  //     },
  //     {
  //       id: 2,
  //       name: "Embossing Tag",
  //       image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Embossing-min_kukhe5.jpg",
  //       price: 8,
  //     },
  //     {
  //       id: 3,
  //       name: "Luggage Tag",
  //       image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Luggage-min_a6zze5.jpg",
  //       price: 8,
  //     },
  //     {
  //       id: 4,
  //       name: "Ribbon Tag",
  //       image: "https://res.cloudinary.com/coximomentz/image/upload/v1633944167/Ribbon-min_wemx08.jpg",
  //       price: 8,
  //     },
  //     {
  //       id: 5,
  //       name: "Mini Topper",
  //       image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Mini_Topper-min_epfvr7.jpg",
  //       price: 6,
  //     },
  //     {
  //       id: 6,
  //       name: "Topper",
  //       image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Topper-min_lz9xyn.jpg",
  //       price: 10,
  //     },
  //   ],
  // },
  {
    id: "Coxibakery-Strawberry-Cream-Cheese",
    image:
      "https://res.cloudinary.com/coximomentz/image/upload/v1630681438/400x400/Coxibakery-Strawberry-Cream-Cheese-1_ia7fjp.png",
    gallery: [
      "https://res.cloudinary.com/coximomentz/image/upload/v1630681831/800x800/Coxibakery-Strawberry-Cream-Cheese-1-min_und5os.png",
      "https://res.cloudinary.com/coximomentz/image/upload/v1630681831/800x800/Coxibakery-Strawberry-Cream-Cheese-2-min_rcsh0w.png",
    ],
    alt: "Coxibakery Strawberry Cream Cheese",
    name: "Korean Strawberry Cheese Cake",
    description: "Korean Strawberry, Creamy Cheese Layer Cake.",
    price: 120,
    ingredients: "Flour, Eggs, Sugar, Whipping Cream, Butter, Salt, Fresh Korean Strawberry",
    allergens: "Milk/Lactose, Eggs",
    weight: "Approx 0.3kg - 1.2kg, depend on size",
    layers: "3 Layers",
    shape: "Round",
    measurement: "https://res.cloudinary.com/coximomentz/image/upload/v1619000554/Round_Measurement_nudddf.svg",
    serving: "2 - 10, depend on size",
    shelf: "2 - 3 days",
    lottie: "https://assets8.lottiefiles.com/private_files/lf30_j3kdw0zi.json",
    premium: false,
    featured: false,
    new: false,
    season: false,
    custom: false,
    allSize: true,
    min6inch: false,
    addOn: [
      {
        id: 5,
        name: "Mini Topper",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Mini_Topper-min_epfvr7.jpg",
        price: 6,
      },
      {
        id: 6,
        name: "Topper",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Topper-min_lz9xyn.jpg",
        price: 10,
      },
    ],
  },
  {
    id: "Coxibakery-Yogurt-Cheese-Cake",
    image:
      "https://res.cloudinary.com/coximomentz/image/upload/v1619256778/400x400/Coxibakery-Yogurt-Cheese-Cake-1-min_ux0mst.png",
    gallery: [
      "https://res.cloudinary.com/coximomentz/image/upload/v1619256718/800x800/Coxibakery-Yogurt-Cheese-Cake-2-min_qjgzwj.png",
      "https://res.cloudinary.com/coximomentz/image/upload/v1619256717/800x800/Coxibakery-Yogurt-Cheese-Cake-1-min_kocrt5.png",
      "https://res.cloudinary.com/coximomentz/image/upload/v1619256718/800x800/Coxibakery-Yogurt-Cheese-Cake-3-min_batdh8.png",
    ],
    alt: "Coxibakery Yogurt Cheese Cake",
    name: "Strawberry Yogurt Cheese Cake",
    description: "Yogurt Cheese Cake, Kiwi and Korean Strawberry.",
    price: 128,
    ingredients:
      "Flour, Eggs, Sugar, Whipping Cream, Butter, Salt, Digestive Biscuits, Cream Cheese, Fresh Korean Strawberry, Fresh Kiwi, Jelly",
    allergens: "Milk/Lactose, Eggs",
    weight: "Approx 0.3kg - 1.2kg, depend on size",
    layers: "2 Layers",
    shape: "Round",
    measurement: "https://res.cloudinary.com/coximomentz/image/upload/v1619000554/Round_Measurement_nudddf.svg",
    serving: "2 - 10, depend on size",
    shelf: "2 - 3 days",
    lottie: "https://assets5.lottiefiles.com/private_files/lf30_nj55fkit.json",
    premium: false,
    featured: false,
    new: false,
    season: false,
    custom: false,
    allSize: false,
    min6inch: false,
    addOn: [
      {
        id: 5,
        name: "Mini Topper",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Mini_Topper-min_epfvr7.jpg",
        price: 6,
      },
      {
        id: 6,
        name: "Topper",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Topper-min_lz9xyn.jpg",
        price: 10,
      },
    ],
  },
  {
    id: "Coxibakery-Lucky-Bag",
    image:
      "https://res.cloudinary.com/coximomentz/image/upload/v1616055005/400x400/Coxibakery-Lucky-Bag-1-min_jynoxw.png",
    gallery: [
      "https://res.cloudinary.com/coximomentz/image/upload/v1615898871/800x800/Coxibakery-Lucky-Bag-1-min_yqn12t.png",
      "https://res.cloudinary.com/coximomentz/image/upload/v1615898871/800x800/Coxibakery-Lucky-Bag-2-min_p5pa52.png",
    ],
    alt: "Coxibakery Lucky Bag",
    name: "Chocolate Strawberry Lucky Bag Cake",
    description:
      'Chocolate Strawberry Cake wrap in Korean "Bojagi" way with less sweet white bean paste instead of fondant icing.',
    price: 188,
    ingredients:
      "Flour, Eggs, Sugar, Whipping Cream, Butter, Salt, Dark Cocoa Powder, White Bean Paste, Strawberry Jam",
    allergens: "Milk/Lactose, Eggs",
    weight: "Approx 0.3kg - 1.2kg, depend on size",
    layers: "3 Layers",
    shape: "Round",
    measurement: "https://res.cloudinary.com/coximomentz/image/upload/v1619000554/Round_Measurement_nudddf.svg",
    serving: "2 - 10, depend on size",
    shelf: "3 - 5 days",
    lottie: "https://assets9.lottiefiles.com/private_files/lf30_dupvtvcs.json",
    premium: false,
    featured: false,
    new: false,
    season: false,
    custom: false,
    allSize: true,
    min6inch: false,
    addOn: [
      {
        id: 1,
        name: "Debossing Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943606/Debossing-min_goo6tc.jpg",
        price: 6,
      },
      {
        id: 2,
        name: "Embossing Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Embossing-min_kukhe5.jpg",
        price: 8,
      },
      {
        id: 3,
        name: "Luggage Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Luggage-min_a6zze5.jpg",
        price: 8,
      },
      {
        id: 4,
        name: "Ribbon Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633944167/Ribbon-min_wemx08.jpg",
        price: 8,
      },
    ],
  },
  {
    id: "Coxibakery-Mango-Jelly-Cake",
    image:
      "https://res.cloudinary.com/coximomentz/image/upload/v1616055005/400x400/Coxibakery-Mango-Jelly-Cake-1-min_ftgfre.png",
    gallery: [
      "https://res.cloudinary.com/coximomentz/image/upload/v1615898871/800x800/Coxibakery-Mango-Jelly-Cake-1-min_yadtuo.png",
      "https://res.cloudinary.com/coximomentz/image/upload/v1615898871/800x800/Coxibakery-Mango-Jelly-Cake-2-min_pznytc.png",
      "https://res.cloudinary.com/coximomentz/image/upload/v1615898872/800x800/Coxibakery-Mango-Jelly-Cake-3-min_pcoiad.png",
    ],
    alt: "Coxibakery Mango Jelly Cake",
    name: "Mango Jelly Cake",
    description: "Mango Jelly Cake with Coconut Aromatic. Unforgetable Local taste. Customize design.",
    price: 120,
    ingredients: "Eggs, Sugar, Salt, Jelly, Coconut Milk, Fresh Mango, Mango Jelly Make by 100% Mango",
    allergens: "Milk/Lactose, Eggs",
    weight: "Approx 0.3kg - 1.2kg, depend on size",
    layers: "6 Layers",
    shape: "Round",
    measurement: "https://res.cloudinary.com/coximomentz/image/upload/v1619000554/Round_Measurement_nudddf.svg",
    serving: "2 - 10, depend on size",
    shelf: "1 - 2 days",
    lottie: "https://assets4.lottiefiles.com/private_files/lf30_f8yddjvg.json",
    premium: false,
    featured: false,
    new: false,
    season: false,
    custom: false,
    allSize: true,
    min6inch: false,
  },
  {
    id: "Coxibakery-Customize-Number-Cake-Topped-with-Kisses-Chocolate",
    image:
      "https://res.cloudinary.com/coximomentz/image/upload/v1630839714/400x400/Coxibakery-Customize-Number-Cake-Topped-with-Kisses-Chocolate-1-min_rb4wnq.png",
    gallery: [
      "https://res.cloudinary.com/coximomentz/image/upload/v1630840010/800x800/Coxibakery-Customize-Number-Cake-Topped-with-Kisses-Chocolate-1-min_fmprzg.png",
      "https://res.cloudinary.com/coximomentz/image/upload/v1630841348/800x800/Coxibakery-Customize-Number-Cake-Topped-with-Kisses-Chocolate-2-min_k9q1uz.png",
      "https://res.cloudinary.com/coximomentz/image/upload/v1631014698/800x800/Coxibakery-Customize-Number-Cake-Topped-with-Kisses-Chocolate-3-min_x03xdm.png",
    ],
    alt: "Coxibakery Customize Number Cake Topped with Kisses Chocolate",
    name: "Customize-Number Cake",
    description:
      "Customize your cake with maximum 2 digits from (0-9). Toppings with Mix Fruits and Hershey's Kisses Chocolate.",
    price: 180,
    ingredients:
      "Flour, Eggs, Sugar, Whipping Cream, Butter, Salt, Fresh Strawberry, Fresh Mango, Hershey's Kisses Chocolate",
    allergens: "Milk/Lactose, Eggs",
    weight: "Approx 600g per Number",
    layers: "3 Layers",
    shape: "Number",
    measurement: "https://res.cloudinary.com/coximomentz/image/upload/v1619012223/Custom_Size_cisd2o.svg",
    serving: "5 - 12, depend on size",
    shelf: "3 - 5 days",
    lottie: "https://assets7.lottiefiles.com/private_files/lf30_9fogbrsq.json",
    premium: false,
    featured: false,
    new: false,
    season: false,
    custom: true,
    allSize: false,
    min6inch: false,
    addOn: [
      {
        id: 1,
        name: "Debossing Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943606/Debossing-min_goo6tc.jpg",
        price: 6,
      },
      {
        id: 2,
        name: "Embossing Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Embossing-min_kukhe5.jpg",
        price: 8,
      },
      {
        id: 3,
        name: "Luggage Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633943607/Luggage-min_a6zze5.jpg",
        price: 8,
      },
      {
        id: 4,
        name: "Ribbon Tag",
        image: "https://res.cloudinary.com/coximomentz/image/upload/v1633944167/Ribbon-min_wemx08.jpg",
        price: 8,
      },
    ],
  },
]

export const Instagram = [
  {
    id: 14,
    image:
      "https://res.cloudinary.com/coximomentz/image/upload/v1621668548/400x400/Instagram/Coxibakery-Instagram-Customer-Yumiko-Melon-Cake-min_xcwm2v.jpg",
    alt: "Coxibakery Instagram - Customer Yumiko Melon Cake",
    link: "https://www.instagram.com/p/CPIlbvxs6-Q/",
  },
  {
    id: 13,
    image:
      "https://res.cloudinary.com/coximomentz/image/upload/v1621668548/400x400/Instagram/Coxibakery-Instagram-Customer-Mandy-Melon-Cake-min_ruj8nn.jpg",
    alt: "Coxibakery Instagram - Customer Mandy Melon Cake",
    link: "https://www.instagram.com/p/CO1oQ2OMIzh/",
  },
  {
    id: 12,
    image:
      "https://res.cloudinary.com/coximomentz/image/upload/v1617334200/400x400/Instagram/Coxibakery-Instagram-Japanese-Strawberry-Cake-min_a44edp.jpg",
    alt: "Coxibakery Instagram - Japanese Strawberry Cake",
    link: "https://www.instagram.com/p/CM_31s6MMil/",
  },
  // {
  //   id: 11,
  //   image:
  //     "https://res.cloudinary.com/coximomentz/image/upload/v1616478766/400x400/Instagram/Coxibakery_Instagram-Watermelon-Mousse-Cake-min_azsqda.jpg",
  //   alt: "Coxibakery Instagram - Watermelon Mousse Cake",
  //   link: "https://www.instagram.com/p/CMq7YIBsRxM/",
  // },
  {
    id: 10,
    image:
      "https://res.cloudinary.com/coximomentz/image/upload/v1616124958/400x400/Instagram/Coxibakery-Instagram-Chocolate-Chestnut-Cake-min_yuwemt.jpg",
    alt: "Coxibakery Instagram - Chocolate Chestnut Cake",
    link: "https://www.instagram.com/p/CMY0Ml9scAw/",
  },
  {
    id: 9,
    image:
      "https://res.cloudinary.com/coximomentz/image/upload/v1616055978/400x400/Instagram/Coxibakery-Instagram-Mango-Mousse-Cake-min_nijuta.jpg",
    alt: "Coxibakery Instagram - Mango Mousse Cake",
    link: "https://www.instagram.com/p/CL3dy9rMAd8/",
  },
  {
    id: 8,
    image:
      "https://res.cloudinary.com/coximomentz/image/upload/v1616055978/400x400/Instagram/Coxibakery-Instagram-Susuwatari-Oreo-Cream-Cheese-Cake-min_sov5gx.jpg",
    alt: "Coxibakery Instagram - Susuwatari Oreo Cream Cheese Cake",
    link: "https://www.instagram.com/p/CLjTNdBsFTz/",
  },
  {
    id: 7,
    image:
      "https://res.cloudinary.com/coximomentz/image/upload/v1616055978/400x400/Instagram/Coxibakery-Instagram-Belgian-Chocolate-Strawberry-Cake-min_yxck5l.jpg",
    alt: "Coxibakery Instagram - Belgian Chocolate Strawberry Cake",
    link: "https://www.instagram.com/p/CLV2H_TsOax/",
  },
  {
    id: 6,
    image:
      "https://res.cloudinary.com/coximomentz/image/upload/v1616055978/400x400/Instagram/Coxibakery-Instagram-Customer-Aiden-Signature-Bittersweet-Chocolate-Banana-min_bt2vwg.jpg",
    alt: "Coxibakery Instagram - Customer Aiden Signature Bittersweet Chocolate Banana",
    link: "https://www.instagram.com/p/CKth4NzsYW2/",
  },
  {
    id: 5,
    image:
      "https://res.cloudinary.com/coximomentz/image/upload/v1619256231/400x400/Instagram/Coxibakery-Instagram-Melon-Cake-min_f2ywoc.jpg",
    alt: "Coxibakery Instagram - Melon Cake",
    link: "https://www.instagram.com/p/CLCTxrWMp9Q/",
  },
  {
    id: 4,
    image:
      "https://res.cloudinary.com/coximomentz/image/upload/v1616055978/400x400/Instagram/Coxibakery-Instagram-Mix-Fruit-Cake-min_mxkp1j.jpg",
    alt: "Coxibakery Instagram - Mix Fruit Cake",
    link: "https://www.instagram.com/p/CK_JkG-Mm8-/",
  },
  {
    id: 3,
    image:
      "https://res.cloudinary.com/coximomentz/image/upload/v1619256376/400x400/Instagram/Coxibakery-Instagram-Signature-Korean-Strawberry-Cake-min_pbg7vr.jpg",
    alt: "Coxibakery Instagram - Signature Korean Strawberry Cake",
    link: "https://www.instagram.com/p/CK_HV0PMciJ/",
  },
  {
    id: 2,
    image:
      "https://res.cloudinary.com/coximomentz/image/upload/v1616055978/400x400/Instagram/Coxibakery-Instagram-Signature-Bittersweet-Chocolate-Banana-min_c7v4jn.jpg",
    alt: "Coxibakery Instagram - Signature Bittersweet Chocolate Banana",
    link: "https://www.instagram.com/p/CKbfnEYMlkn/",
  },
  {
    id: 1,
    image:
      "https://res.cloudinary.com/coximomentz/image/upload/v1616055978/400x400/Instagram/Coxibakery-Instagram-Charlotte-Cake-with-Green-Grape-min_eafkps.jpg",
    alt: "Coxibakery Instagram - Charlotte Cake with Green Grape",
    link: "https://www.instagram.com/p/CKI3VBtsjQG/",
  },
]

// {
//     id: "Coxibakery-Christmas-Theme-Strawberry-Chocolate-Cake",
//     image: 'https://res.cloudinary.com/coximomentz/image/upload/v1616055004/400x400/Coxibakery-Christmas-Theme-Strawberry-Chocolate-Cake-min_kizapi.png',
//     gallery: [
//         'https://res.cloudinary.com/coximomentz/image/upload/v1615898869/800x800/Coxibakery-Christmas-Theme-Strawberry-Chocolate-Cake-min_eggkyi.png',
//     ],
//     alt: "Coxibakery Christmas Theme Strawberry Chocolate Cake",
//     name: "Santa's Strawberry Cake",
//     description: "Korean Strawberry + Chocolate Layer Cake.",
//     price: "45.00",
//     ingredients: "Flour, Eggs, Sugar, Whipping Cream, Butter, Salt, Cocoa Powder, Fresh Korean Strawberry",
//     allergens: "Milk/Lactose, Eggs",
//     weight: "Approx 0.3kg - 1.2kg, depend on size",
//     layers: "3 Layers",
//     shape: "Round",
//     measurement: "https://res.cloudinary.com/coximomentz/image/upload/v1619000554/Round_Measurement_nudddf.svg",
//     serving: "2 - 10, depend on size",
//     shelf: "2 - 3 days",
//     lottie: "https://assets8.lottiefiles.com/private_files/lf30_zzpi9oza.json",
//     featured: false,
//     new: false,
//     season: false,
//     custom: false,
// },
