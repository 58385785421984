import CartActionTypes from './cart.types'
import { addCakeToCart, removeCakeFromCart, addItemToCart, removeItemFromCart } from "./cart.utils";

const INITIAL_STATE = {
    showDropdown: false,
    cartItems: [],
    userInfo: [],
    paymentMethod: []
}

const cartReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CartActionTypes.TOGGLE_CART_HIDDEN:
            return {
                ...state,
                showDropdown: !state.showDropdown
            }
        case CartActionTypes.TOGGLE_CLOSE_CART:
            return {
                ...state,
                showDropdown: false
            }
        case CartActionTypes.ADD_CAKE:
            return {
                ...state,
                cartItems: addCakeToCart(state.cartItems, action.payload)
            }
        case CartActionTypes.REMOVE_CAKE:
            return {
                ...state,
                cartItems: removeCakeFromCart(state.cartItems, action.payload)
            }
        case CartActionTypes.ADD_ITEM:
            return {
                ...state,
                cartItems: addItemToCart(state.cartItems, action.payload)
            }
        case CartActionTypes.REMOVE_ITEM:
            return {
                ...state,
                cartItems: removeItemFromCart(state.cartItems, action.payload)
            }
        case CartActionTypes.CLEAR_ITEM_FROM_CART:
            return {
                ...state,
                cartItems: state.cartItems.filter(cartItem =>
                    cartItem.uuid !== action.payload.uuid
                )
            }
        // case CartActionTypes.CLEAR_ITEM_FROM_CART:
        //     return {
        //         ...state,
        //         cartItems: state.cartItems.filter(cartItem => cartItem.id !== action.payload.id)
        //     }
        case CartActionTypes.EMPTY_CART:
            return {
                ...state,
                cartItems: []
            }
        case CartActionTypes.SUBMIT_USER_INFO:
            return {
                ...state,
                userInfo: action.payload
            }
        case CartActionTypes.SUBMIT_PAYMENT_METHOD:
            return {
                ...state,
                paymentMethod: action.payload
            }
        default:
            return state
    }
}

export default cartReducer