export const addItemToCart = (cartItems, cartItemToAdd) => {
  const existingCartItem = cartItems.find((cartItem) => cartItem.id === cartItemToAdd.id)

  if (existingCartItem) {
    return cartItems.map((cartItem) =>
      cartItem.id === cartItemToAdd.id ? { ...cartItem, quantity: cartItem.quantity + 1 } : cartItem
    )
  }

  return [...cartItems, { ...cartItemToAdd, quantity: 1 }]
}

export const removeItemFromCart = (cartItems, cartItemToRemove) => {
  const existingCartItem = cartItems.find((cartItem) => cartItem.id === cartItemToRemove.id)

  if (existingCartItem.quantity === 1) {
    return cartItems.filter((cartItem) => cartItem.id !== cartItemToRemove.id)
  }

  return cartItems.map((cartItem) =>
    cartItem.id === cartItemToRemove.id ? { ...cartItem, quantity: cartItem.quantity - 1 } : cartItem
  )
}

export const addCakeToCart = (cartItems, cartItemToAdd) => {
  const existingCartItem = cartItems.find(
    (cartItem) =>
      cartItem.size === cartItemToAdd.size &&
      cartItem.date === cartItemToAdd.date &&
      cartItem.deliveryTime === cartItemToAdd.deliveryTime &&
      cartItem.candle === cartItemToAdd.candle
    // cartItem.bigCandle === cartItemToAdd.bigCandle &&
    // cartItem.smallCandle === cartItemToAdd.smallCandle
  )

  if (existingCartItem) {
    return cartItems.map((cartItem) =>
      cartItem.size === cartItemToAdd.size &&
      cartItem.date === cartItemToAdd.date &&
      cartItem.deliveryTime === cartItemToAdd.deliveryTime &&
      cartItem.candle === cartItemToAdd.candle
        ? // cartItem.bigCandle === cartItemToAdd.bigCandle &&
          // cartItem.smallCandle === cartItemToAdd.smallCandle
          { ...cartItem, quantity: cartItem.quantity + 1 }
        : cartItem
    )
  }

  return [...cartItems, { ...cartItemToAdd, quantity: 1 }]
}

export const removeCakeFromCart = (cartItems, cartItemToRemove) => {
  const existingCartItem = cartItems.find(
    (cartItem) =>
      cartItem.size === cartItemToRemove.size &&
      cartItem.date === cartItemToRemove.date &&
      cartItem.deliveryTime === cartItemToRemove.deliveryTime &&
      cartItem.candle === cartItemToRemove.candle
    // cartItem.bigCandle === cartItemToRemove.bigCandle &&
    // cartItem.smallCandle === cartItemToRemove.smallCandle
  )

  if (existingCartItem.quantity === 1) {
    return cartItems.filter((cartItem) => cartItem.uuid !== cartItemToRemove.uuid)
  }

  return cartItems.map((cartItem) =>
    cartItem.size === cartItemToRemove.size &&
    cartItem.date === cartItemToRemove.date &&
    cartItem.deliveryTime === cartItemToRemove.deliveryTime &&
    cartItem.candle === cartItemToRemove.candle
      ? // cartItem.bigCandle === cartItemToRemove.bigCandle &&
        // cartItem.smallCandle === cartItemToRemove.smallCandle
        { ...cartItem, quantity: cartItem.quantity - 1 }
      : cartItem
  )
}
