import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';
import HttpsRedirect from 'react-https-redirect';

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <PersistGate persistor={persistor}>
        <HelmetProvider>
          <HttpsRedirect>
            <App />
          </HttpsRedirect>
        </HelmetProvider>
      </PersistGate>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);
